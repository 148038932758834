import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { DocumentTemplate } from "@tradecafe/types/core";
import { DeepReadonly } from "@tradecafe/types/utils";
import { switchMap, take } from "rxjs/operators";
import { deleteDocumentTemplate, selectAllDocumentTemplates } from "src/app/store/document-templates";
import { ConfirmModalService } from "src/components/confirm/confirm-modal.service";
import { ModalService } from "src/shared/modal";
import { waitNotEmpty } from "src/shared/utils/wait-not-empty";
import { DocumentFormComponent, DocumentFormOptions } from "./document-form.component";

@Injectable()
export class DocumentFormService {
  constructor(
    private modal: ModalService,
    private store: Store,
    private ConfirmModal: ConfirmModalService,
  ) {}

  private documents$ = this.store.pipe(
    select(selectAllDocumentTemplates),
    waitNotEmpty(),
    take(1))

  showCreateDocument() {
    return this.documents$.pipe(switchMap(documents =>
      this.modal.openDialog<DocumentFormComponent, DocumentFormOptions, DocumentTemplate>(DocumentFormComponent, {
        title: 'Create Document',
        documents,
      }))
    ).toPromise()
  }

  showUpdateDocument(template: DeepReadonly<DocumentTemplate>) {
    return this.documents$.pipe(switchMap(documents =>
      this.modal.openDialog<DocumentFormComponent, DocumentFormOptions, DocumentTemplate>(DocumentFormComponent, {
        title: 'Update Document',
        document: template,
        documents,
      }))
    ).toPromise()
  }

  async showDeleteItem(template: DeepReadonly<DocumentTemplate>) {
    await this.ConfirmModal.show({
      title: 'Delete this document?',
      description: 'Are you sure you want to delete this document?',
    })
    this.store.dispatch(deleteDocumentTemplate({ id: template.document_id, template }))
  }
}
