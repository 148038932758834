import { createSelector } from '@ngrx/store'
import { DocumentSet } from '@tradecafe/types/core'
import { State } from '../reducers'
import { adapter, documentSetsFeatureKey } from './document-set.reducer'

export const selectState = (state: State) => state[documentSetsFeatureKey]

export const { selectEntities: selectDocumentSetEntities, selectAll: selectAllDocumentSets } = adapter.getSelectors(selectState)

export const selectDocumentSet = createSelector(
  selectDocumentSetEntities,
  (documentSets: Dictionary<DocumentSet>, documentSet: string | number) =>
    documentSets[documentSet])

export const selectDocumentSetsByIds = createSelector(
  selectDocumentSetEntities,
  (documentSets: Dictionary<DocumentSet>, documentSetIds: string[]) =>
    documentSetIds.map(documentSetId => documentSets[documentSetId]))
