import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DocumentTemplate } from '@tradecafe/types/core';
import { DeepReadonly } from '@tradecafe/types/utils';

export interface DocumentTagsOverlayOptions {
  document: DeepReadonly<DocumentTemplate>
}

@Component({
  selector: 'tc-document-tags-overlay',
  templateUrl: './document-tags-overlay.component.html',
  styleUrls: ['./document-tags-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentTagsOverlayComponent {
  constructor(@Inject(MAT_DIALOG_DATA) private dialogData: DocumentTagsOverlayOptions) { }
  protected tags = this.dialogData.document.attributes.requirements.map(r => r.tag)
}
