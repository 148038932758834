import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { getTags, TagDefinition } from '@tradecafe/documentlibrary-tags';
import { DocumentTemplate } from '@tradecafe/types/core';
import { DeepReadonly } from '@tradecafe/types/utils';
import { map, pick } from 'lodash-es';
import { of } from 'rxjs';
import { ScrollableDataSource } from 'src/services/table-utils/data-sources/scrollable-data-source';


export interface DocumentTagRow extends TagDefinition {
  name: string
  templates?: DeepReadonly<DocumentTemplate[]>
}

@Component({
  selector: 'tc-document-tags-list',
  templateUrl: './document-tags-list.component.html',
  styleUrls: ['./document-tags-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentTagsListComponent implements OnInit {

  @Input() tags: string[]
  @Input() displayColumns = ['name', 'description']
  @Input() dataSource: ScrollableDataSource<DocumentTagRow, any>
  @Output() showDocuments = new EventEmitter<string>()


  @ViewChild(MatSort) set sort(sort: MatSort) { this.dataSource.setSort(sort) }
  @ViewChild(MatPaginator) set paginator(paginator: MatPaginator) { this.dataSource.setPaginator(paginator) }
  @ViewChild(CdkVirtualScrollViewport) set viewPort(viewPort: CdkVirtualScrollViewport) { this.dataSource.setViewPort(viewPort) }

  @HostBinding('style.--rows-visible') get _rowsVisible() {
    return Math.min(this.dataSource.paginator?.pageSize || Number.POSITIVE_INFINITY, this.dataSource.filteredData?.length || 0)
  }

  ngOnInit(): void {
    const data = map(this.tags ? pick(getTags(), this.tags) : getTags(), (tag, name) => ({ ...tag, name }))
    this.dataSource = this.dataSource || new ScrollableDataSource(of(data))
  }

  getRowId = (_index: number, tag: DocumentTagRow) => tag.name
}
