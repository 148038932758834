<cdk-virtual-scroll-viewport
    tvsItemSize="41" [itemSize]="41" headerHeight="56" bufferMultiplier="3"
    (scrolledIndexChange)="dataSource.onScroll($event)">
  <table mat-table
      [dataSource]="dataSource" [trackBy]="getRowId"
      matSort matSortStart="desc" matSortDisableClear>

    <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayColumns" [class.tc-loading-row]="!row"></tr>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header title="Name">Name</th>
      <td mat-cell *matCellDef="let tag; dataSource:dataSource">
        <ng-container *ngIf="!tag">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="tag">
          <div class="text-ellipsis" [title]="tag.name">{{tag.name}}</div>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header title="Description">Description</th>
      <td mat-cell *matCellDef="let tag; dataSource:dataSource">
        <ng-container *ngIf="!tag">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="tag">
          <div class="text-ellipsis" [title]="tag.description">{{tag.description}}</div>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="ellipsisMenu">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let tag; dataSource:dataSource">
        <ng-container *ngIf="!tag">
          <div class="skeleton-loader circle"></div>
        </ng-container>
        <ng-container>
          <button mat-icon-button type="button" [matMenuTriggerFor]="ellipsisMenu" [matMenuTriggerData]="{tag: tag}">
            <mat-icon class="fa fa-2x fa-ellipsis-v"></mat-icon>
          </button>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
  </table>
</cdk-virtual-scroll-viewport>

<mat-paginator #paginator></mat-paginator>

<mat-menu #ellipsisMenu="matMenu" yPosition="below" class="nav-right-menu">
  <ng-template matMenuContent let-tag="tag">
    <button mat-menu-item (click)="showDocuments.next(tag.name)">
      <i class="fa fa-fw fa-file-lines"></i> Show Documents
    </button>
  </ng-template>
</mat-menu>
