<h2 mat-dialog-title>
  <i class="fa fa-pencil" aria-hidden="true"></i>&nbsp;{{title}}
</h2>
<form [formGroup]="documentForm" (submit)="save()">
  <mat-dialog-content gdColumns="repeat(2, minmax(0, 1fr))" gdGap="0 15px">
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input matInput formControlName="name">
    </mat-form-field>
    <div *ngIf="!(documentFile$ | async)" style="position: relative;margin-top: 10px">
      <input #fileInput type="file" accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document" (change)="upload($event)">
      <button mat-raised-button color="primary" type="button" class="upload-document-button" (click)="fileInput.click()">
        <i class="fa fa-cloud-arrow-up"></i> Upload
      </button>
    </div>
    <div *ngIf="documentFile$ | async" style="margin-top: -10px">
      <button mat-button type="button" (click)="download()"
          [matTooltip]="'Download ' + (documentFile$ | async)?.name">
        <mat-icon class="fa fa-fw fa-cloud-arrow-down"></mat-icon> {{ (documentFile$ | async)?.name }}
      </button><br>
      <button mat-button type="button" (click)="removeDocumentFile()" aria-label="Remove file">
        <mat-icon class="fa fa-xmark" style="color: red"></mat-icon> remove file
      </button>
    </div>
    <mat-form-field>
      <mat-label>Type</mat-label>
      <input matInput formControlName="type">
    </mat-form-field>
    <tc-select-search placeholder="Language"
        [ctrl]="documentForm.controls.language"
        [items]="LANGUAGES" bindLabel="label" bindValue="id"
        ></tc-select-search>
    <tc-select-search placeholder="Product Category"
        [ctrl]="documentForm.controls.product_category_id"
        [items]="categories$ | async" bindLabel="name" bindValue="category_id"
        ></tc-select-search>
    <tc-select-search placeholder="Company"
        [ctrl]="documentForm.controls.company"
        [items]="companies$ | async" bindLabel="name" bindValue="id" bindHint="type"
        ></tc-select-search>
    <tc-select-search placeholder="Document set" [multiple]="true"
        [ctrl]="documentForm.controls.doc_set"
        [items]="documentSets$ | async" bindLabel="name" bindHint="country" bindValue="set_id"
        tc-option-disabled="disabledDocset"
        ></tc-select-search>
    <tc-select-search placeholder="Traders" [multiple]="true"
        [ctrl]="documentForm.controls.traders"
        [items]="traders$ | async" bindLabel="name" bindValue="id"
        ></tc-select-search>
    <mat-form-field gdColumn="span 2">
      <mat-label>Description</mat-label>
      <textarea matInput formControlName="description"></textarea>
    </mat-form-field>
  </mat-dialog-content>
  <mat-progress-bar [mode]="(inProgress$|async) ? 'indeterminate' : undefined"></mat-progress-bar>
  <mat-dialog-actions align="end">
    <button mat-button type="button" (click)="cancel()">Cancel</button>
    <button mat-raised-button type="submit" color="primary" [disabled]="inProgress$ | async">
      <i class="fa fa-fw" aria-hidden="true"
          [class.fa-save]="(inProgress$ | async) !== 'save'"
          [class.fa-spin]="(inProgress$ | async) === 'save'"
          [class.fa-spinner]="(inProgress$ | async) === 'save'"
          ></i>
      Save
    </button>
  </mat-dialog-actions>
</form>
