import { Injectable } from '@angular/core';
import { DocumentTemplate } from '@tradecafe/types/core';
import { DeepReadonly } from '@tradecafe/types/utils';
import { ModalService } from 'src/shared/modal';
import { DocumentTagsOverlayComponent, DocumentTagsOverlayOptions } from './document-tags-overlay.component';

@Injectable()
export class DocumentTagsOverlayService {

  constructor(private modal: ModalService) { }

  showDocumentTags(document: DeepReadonly<DocumentTemplate>) {
    return this.modal.openLargeSide<DocumentTagsOverlayComponent, DocumentTagsOverlayOptions, void>(DocumentTagsOverlayComponent, { document }).toPromise()
  }
}
