import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { createReducer, on } from '@ngrx/store'
import { DocumentSet } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import * as DocumentSetActions from './document-set.actions'

export const documentSetsFeatureKey = 'documentSets'
export interface DocumentSetsState extends EntityState<DeepReadonly<DocumentSet>> {
}

export const selectId = (documentSet: DocumentSet) => documentSet.set_id
export const adapter = createEntityAdapter<DeepReadonly<DocumentSet>>({ selectId })

const initialState: DocumentSetsState = adapter.getInitialState({
})

export const documentSetsReducer = createReducer(
  initialState,
  on(DocumentSetActions.loadDocumentSetsSuccess,
    (state, action) =>
      adapter.upsertMany(action.documentSets, state)),
  on(DocumentSetActions.createDocumentSetSuccess,
    (state, action) =>
      adapter.addOne(action.set, state)),
  on(DocumentSetActions.updateDocumentSetSuccess,
    (state, action) =>
      adapter.updateOne({ id: action.set.set_id, changes: action.set }, state)),
  on(DocumentSetActions.deleteDocumentSetSuccess,
    (state, action) =>
      adapter.removeOne(action.id, state)),
)

